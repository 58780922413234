var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "white", height: _vm.barHeight } },
        [
          _c("img", {
            attrs: {
              id: "logo",
              src: require("@/assets/web-figures/logo.png"),
              height: _vm.logoHeight,
            },
            on: { click: _vm.moveToTop },
          }),
          _c("v-spacer"),
          _vm.$vuetify.breakpoint.forPC
            ? [
                _c(
                  "v-btn",
                  {
                    staticClass: "app-bar-button",
                    attrs: { text: "", href: "/" },
                  },
                  [_vm._v(" トップ ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "app-bar-button",
                    attrs: { text: "", href: "/features" },
                  },
                  [_vm._v(" 機能 ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "app-bar-button",
                    attrs: { text: "", href: "/prices" },
                  },
                  [_vm._v(" 料金・導入フロー ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "app-bar-button",
                    attrs: {
                      text: "",
                      "append-icon": "mdi-open-in-new",
                      href: "https://www.iflab.co.jp/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(" 会社概要"),
                    _c("v-icon", { attrs: { right: "" } }, [
                      _vm._v("mdi-open-in-new"),
                    ]),
                  ],
                  1
                ),
                _c("contact-button", { staticClass: "ml-12" }),
              ]
            : [
                _c("v-app-bar-nav-icon", {
                  on: {
                    click: function ($event) {
                      _vm.drawer = true
                    },
                  },
                }),
              ],
        ],
        2
      ),
      _c("navigation-drawer", {
        model: {
          value: _vm.drawer,
          callback: function ($$v) {
            _vm.drawer = $$v
          },
          expression: "drawer",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }