var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "font-weight-bold",
      style: _vm.styles.button,
      attrs: {
        dark: "",
        rounded: "",
        "x-large": "",
        color: "black",
        href: "/#contact",
      },
    },
    [_vm._v("お問い合わせ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }