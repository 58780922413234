var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { temporary: "", right: "", fixed: "", height: "auto" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "wrapper" } },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("img", {
                attrs: {
                  id: "logo",
                  src: require("@/assets/web-figures/logo.png"),
                },
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-large": "" },
                  on: {
                    click: function ($event) {
                      _vm.shown = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            _vm._l(_vm.listItems, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: "drawer-list-item-" + i,
                  attrs: { href: item.href },
                  on: {
                    click: function ($event) {
                      _vm.shown = false
                    },
                  },
                },
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(item.title) },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }